export const networkCollections = {
    "0x1": [
        {
            image:
              "https://lh3.googleusercontent.com/LIov33kogXOK4XZd2ESj29sqm_Hww5JSdO7AFn5wjt8xgnJJ0UpNV9yITqxra3s_LMEW1AnnrgOVB_hDpjJRA1uF4skI5Sdi_9rULi8=s0",
            name: "Cool Cats",
            addrs: "0x1a92f7381b9f03921564a437210bb9396471050c",
        },
        {
            image:
              "https://lh3.googleusercontent.com/NJmc2RTdO44kJ6dX9mC3EZoOL5j97hYVzdI214MYg_xFf6pop3o8pEhb1kuachm7vpLXDrJxpncUCM3InCl0f7oyddkkesnvc7Z7LA=w600",
            name: "CryptoPunks",
            addrs: "0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb",
        },
        {
            image:
              "https://lh3.googleusercontent.com/1up7IvZnXj99OAavLkjdahY4ROjwqOKDvgmPxceirndDuW_YJUOToHGJldUI0LjtFTUFbHfikAMN5nr_uWxi1tcVtjhJN45DAwV_QQ=w600",
            name: "Bored Ape Yacht Club",
            addrs: "0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D",
        },
        {
            image:
              "https://lh3.googleusercontent.com/Wu8xZ-y9vQUMob4w4B098e7ftWlJb3GGTyeCfIclHIVd20WOYNSqO4qC3CGAk9DQ4-wcxpClqPE5g5brQZrOS4BM1GrmEjKrsj_s=w600",
            name: "Doodles",
            addrs: "0x8a90cab2b38dba80c64b7734e58ee1db38b8992e",
        },                        
    ],

    "0x13881": [
        {
            image: 
              "https://lh3.googleusercontent.com/lv4mabbXz-JcoijZjkwTIWbLJ5t2nBaX3K4IdmOID3aeIGnYKdU-LbBPcy2t9fhp8X_7b7DiMt-IDhEOwOpU6hS1qR0yFwJpIp3P=w600",
            name: "Mutant Ape Yacht Club",
            addrs: "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
        },
        {
            image: 
              "https://lh3.googleusercontent.com/foWna7Bq7rG4-xoWSONa8jZRvoWSuz7CnbLZMH6vrcYtFkZADdorI6Hx4-B3dgsk-pSQMCu17cxbBwsc1jYPqyqAv5Boxg5sQoSDs7U=w600",
            name: "Bored Bunny",
            addrs: "0x8a90cab2b38dba80c64b7734e58ee1db38b8992e",
        },        
    ],

    "0x2a": [
      {
          image: 
            "https://lh3.googleusercontent.com/lv4mabbXz-JcoijZjkwTIWbLJ5t2nBaX3K4IdmOID3aeIGnYKdU-LbBPcy2t9fhp8X_7b7DiMt-IDhEOwOpU6hS1qR0yFwJpIp3P=w600",
          name: "Mutant Ape Yacht Club",
          addrs: "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
      },
      {
          image: 
            "https://lh3.googleusercontent.com/foWna7Bq7rG4-xoWSONa8jZRvoWSuz7CnbLZMH6vrcYtFkZADdorI6Hx4-B3dgsk-pSQMCu17cxbBwsc1jYPqyqAv5Boxg5sQoSDs7U=w600",
          name: "Bored Bunny",
          addrs: "0x8a90cab2b38dba80c64b7734e58ee1db38b8992e",
      },        
  ],
    
};

export const getCollectionsByChain = (chain) => networkCollections[chain];